import React from "react";
import "./index.less";
import { IconStarFill } from "@arco-design/web-react/icon";
function LoadingDom(props) {
  const { children, isLoading } = props;
  return (
    <div className="_root">
      <div className="mask" style={{ display: isLoading ? "block" : "none" }}>
        <div className="loading">
          <span>摘</span>
          <span>
            <IconStarFill color="#ffcd36" />
          </span>
          <span>方</span>
          <span>舟</span>
        </div>
      </div>
      {children}
    </div>
  );
}

export default LoadingDom;
