const getMp3Duration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    if (file.type !== "audio/mpeg") {
      return reject(new Error("文件不是 MP3 格式"));
    }

    const url = URL.createObjectURL(file);
    const audio = new Audio(url);

    const onLoadedMetadata = () => {
      const duration = audio.duration;
      cleanup();
      resolve(duration);
    };

    const cleanup = () => {
      URL.revokeObjectURL(url);
      audio.removeEventListener("loadedmetadata", onLoadedMetadata);
    };

    audio.addEventListener("error", () => {
      cleanup();
      reject(new Error("无法加载音频文件"));
    });

    audio.addEventListener("loadedmetadata", onLoadedMetadata, { once: true });

    audio.load();
  });
};

export default getMp3Duration;
