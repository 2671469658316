import { ReactNode, useEffect, useRef, useState } from "react";
import { IconCaretRight } from "@arco-design/web-react/icon";
import "./VideoHoc.less";

export interface IVideoHocProps {
  url: string;
  children?: ReactNode;
  style: React.CSSProperties;
  disMuted?: boolean;
}

export function formatTime(milliseconds: number) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
}

const VideoHoc: React.FC<IVideoHocProps> = (props) => {
  const { url, children, style, disMuted } = props;
  const [videoVisible, setVideoVisible] = useState(false);
  const videoHoc = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const handleVideoHocMouseOver = () => {
    setVideoVisible(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      videoRef.current.src = "";
    }
  };

  useEffect(() => {
    const handleMouseLeave = (event: MouseEvent) => {
      if (
        videoHoc.current &&
        !videoHoc.current.contains(event.relatedTarget as Node)
      ) {
        handleVideoHocMouseOver();
      }
    };
    videoHoc.current.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      if (videoHoc.current) {
        videoHoc.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const handlePlayClick = () => {
    setVideoVisible(true);
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.src = url;
        videoRef.current.play().catch((error) => {
          console.error("Video play failed: ", error);
        });
      } else {
        console.log("没有video元素");
      }
    }, 0);
  };

  const handleVideoEnd = () => {
    setVideoVisible(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.src = "";
    }
  };

  return (
    <div className="_video-hoc-wrapper" style={style} ref={videoHoc}>
      {!videoVisible ? (
        <div className="_children-wrapper">{children}</div>
      ) : (
        <></>
      )}

      {!videoVisible && (
        <div className="_video-play-button" onClick={handlePlayClick}>
          <IconCaretRight />
        </div>
      )}
      {videoVisible && (
        <div className="_video-wrapper">
          <video
            crossOrigin="anonymous"
            ref={videoRef}
            controls={false}
            muted={!disMuted}
            onEnded={handleVideoEnd}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 100,
            }}></video>
        </div>
      )}
    </div>
  );
};

export default VideoHoc;
