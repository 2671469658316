/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from "react";
import "./index.less";
import Modals from "@/components/UI/Modals";
import { Button, Image, Input, Message } from "@arco-design/web-react";
import { addTeam } from "@/services/user/user";
import { setTokenToLocalStorage } from "@/utils/token";
import User from "@/stores/userStore";
import { add_msg_icon } from "@/assets/user-center";
interface ICreateTeamProps {
  isTeam: boolean;
  visible: boolean;
  handleChangeMoadl: () => void;
}
const CreateTeam: React.FC<ICreateTeamProps> = ({
  visible,
  isTeam,
  handleChangeMoadl,
}) => {
  const [value, setValue] = useState("");
  const createTeamFun = async () => {
    const res = await addTeam({
      name: value,
    });
    if (res?.code == 0) {
      setTokenToLocalStorage(res.data.token || "");
      handleChangeMoadl();
      Message.success("创建成功");
      User.getTeamInfo();
    } else {
      Message.error(res);
    }
  };

  return (
    <Modals
      width={464}
      title="创建团队"
      cancelBtn={
        <Button
          onClick={() => {
            handleChangeMoadl();
            setValue("");
          }}>
          取消
        </Button>
      }
      subMiteBtn={
        <Button
          disabled={!value}
          type="primary"
          onClick={() => {
            createTeamFun();
          }}>
          确认
        </Button>
      }
      titleIcon={<Image width={18} preview={false} src={add_msg_icon} />}
      visible={visible}>
      <div className="user-create-team-moadl">
        <div className="team-moadl-msg">
          {!isTeam ? (
            <>
              <h1>你还没有加入任何团队</h1>
              <p>快来创建一个属于自己的团队吧</p>
            </>
          ) : (
            <h1>HI～快来给新团队取个名字吧</h1>
          )}
        </div>
        <div className="team-moadl-input">
          <Input
            value={value}
            placeholder="请输入团队名称"
            onChange={(e) => {
              setValue(e);
            }}
            maxLength={20}
            showWordLimit
          />
        </div>
      </div>
    </Modals>
  );
};

export default CreateTeam;
