import "./SimpleBream.less";

export interface IBreamProps {
  list: any[];
  handleBreamCrumbItemClick: (item: any) => void;
}

const SimpleBream: React.FC<IBreamProps> = (props) => {
  const { list, handleBreamCrumbItemClick } = props;
  return (
    <ul className="bread-crumb">
      {list.map((item, index) => {
        return (
          <li key={item.id}>
            {index ? <span className="interval">/</span> : ""}
            <span
              className={`${index === list.length - 1 ? "active" : ""}  small-item`}
              onClick={() => {
                handleBreamCrumbItemClick(item);
              }}>
              {item.name}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default SimpleBream;
