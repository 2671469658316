import React, { useEffect, useState } from "react";
import { Button, Image, Modal } from "@arco-design/web-react";
import { IconClose } from "@arco-design/web-react/icon";
import { QRcode_icon, issue_icon } from "@/assets/workbenchimage";
import "./index.less";
import { wechatQrcode } from "@/services/workbenck/workbenck";
interface IMyServiceProps {
  visible: boolean;
  setVisible: () => void;
}
const MyService: React.FC<IMyServiceProps> = (props) => {
  const { visible, setVisible } = props;
  const [randomTitle, setRandomTitle] = useState("");
  const titleContent = ["您还没有购买摘星权益", "", "您的试用套餐已到期"];
  const randomIndex = Math.floor(Math.random() * titleContent.length);
  const [qRCodeIcon, setQRCodeIcon] = useState<string>("");
  const getWechatQrcode = async () => {
    const res = await wechatQrcode();
    if (res) {
      setQRCodeIcon(res.data.sales_wechat_qrcode);
    }
  };
  useEffect(() => {
    setRandomTitle(titleContent[randomIndex]);
    if (visible) {
      getWechatQrcode();
    }
  }, [visible]);
  return (
    <Modal
      title={null}
      footer={null}
      closable={false}
      focusLock={false}
      autoFocus={false}
      visible={visible}
      style={{ width: 464 }}>
      <div className="chickensoup-modal">
        <div className="chickensoup-header">
          <div className="chickensoup-title">
            <div>
              <IconClose onClick={setVisible} />
            </div>
          </div>
        </div>
        <div className="chickensoup-content">
          {randomTitle ? <h1>{randomTitle}</h1> : <></>}
          <p>请扫描二维码添加客服进行咨询</p>
          <Image src={qRCodeIcon} width={158} />
          {/* <Button type="primary" style={{ margin: "20px 0", borderRadius: 2 }}>
            复制邮箱
          </Button> */}
        </div>
      </div>
    </Modal>
  );
};

export default MyService;
