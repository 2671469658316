import { useState } from "react";
import parseMovieGetCoverAndTime from "./parseMovieGetCoverAndTime";
import getMp3Duration from "./getMp3Duration";
import mp3Img from "@/assets/create-space/mp3.png";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";

export interface IUseParseFileProps {
  type: CardTypeEnum;
  duration: number | string;
  img: string;
  parseFile: (e: File) => void;
}

// 判断类型
export const getFileType = (file: File): CardTypeEnum => {
  console.log(file);
  if (
    file.type === "video/mp4" ||
    file.type === "video/quicktime" ||
    file.type === "video/x-flv"
  ) {
    return CardTypeEnum.MOVIE;
  }
  if (file.type === "image/png" || file.type === "image/jpeg") {
    return CardTypeEnum.PICTURE;
  }
  if (file.type === "audio/mpeg") {
    return CardTypeEnum.MUSIC;
  }
};

export const fileToUrl = (file: File) => {
  return URL.createObjectURL(file);
};

const useParseFile = (): IUseParseFileProps => {
  // const useParseFile: any = (file: File) => {
  const [type, setType] = useState<CardTypeEnum>("" as CardTypeEnum.MOVIE);
  const [duration, setDuration] = useState<number>(0);
  const [img, setImg] = useState<string>("");

  const parseFile = async (e: File) => {
    const fileType = getFileType(e);
    setType(fileType);
    switch (fileType) {
      case CardTypeEnum.MOVIE: {
        const { img, duration } = await parseMovieGetCoverAndTime(e);
        setImg(img);
        setDuration(duration);
        return;
      }
      case CardTypeEnum.MUSIC: {
        const time = await getMp3Duration(e);
        setImg(mp3Img);
        setDuration(time);
        return;
      }

      case CardTypeEnum.PICTURE: {
        setImg(fileToUrl(e));
        return;
      }
      default:
        return;
    }
  };

  return {
    type,
    duration,
    img,
    parseFile,
  };
};

export default useParseFile;
