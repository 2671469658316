import React, { useEffect, useState } from "react";
import "./index.less";
import LazyImage from "@/components/business/LazyLoadImage";
import { logoImg, zhaixing_logo } from "@/assets/headimage";
import MyTask from "@/components/UI/MyTask";
import MyBillboard from "@/components/business/MyBillboard";
import MyUser from "@/components/business/User";
import { useNavigate } from "react-router-dom";
import User from "@/stores/userStore";
import { observer } from "mobx-react";
const Header: React.FC = (props) => {
  const chickenSoup = [
    "创意火花绽放，勇敢探索，成就独特璀璨作品",
    "嘿~ 快来！在这里实现你的无限创意",
    "多用AI，让你的作品成为独一无二的璀璨星辰",
    "每一个灵感都是宝藏",
    "大胆想象，让你的作品成为惊艳时光的艺术传奇",
    "用心剪辑每一帧，以帧为笔，绘出绚丽画卷",
    "剪辑视频，那就是在雕琢时光",
    "沉浸剪辑工作，用影像传递温暖力量",
    "灵感如星，照亮创意的浩瀚天空",
    "AI 赋能，高效便捷，快来体验未来新趋势",
    "借助 AI 之力，轻松解决难题，畅享智慧之旅",
    "拥抱 AI，让 AI 成为你的得力助手",
  ];
  const [randomTitle, setRandomTitle] = useState("");
  const randomIndex = Math.floor(Math.random() * chickenSoup.length);
  const navigate = useNavigate();
  useEffect(() => {
    setRandomTitle(chickenSoup[randomIndex]);
  }, []);
  return (
    <div className="header_root">
      <div
        className="logo-box"
        onClick={() => {
          navigate("/work-bench");
        }}>
        {/* <LazyImage src={logoImg} width={156} alt="logo" /> */}
        <div style={{ width: User.logoWidth, textAlign: "center" }}>
          <img
            src={User.logoWidth == 107 ? logoImg : zhaixing_logo}
            width={User.logoWidth == 107 ? 37 : 150}
            alt=""
            style={{ marginTop: 2 }}
          />
        </div>
        <p className="head-text">{randomTitle}</p>
      </div>
      <div className="tool-box">
        <div>
          <MyTask />
        </div>
        {/* <div>
          <MyBillboard />
        </div> */}
        <div>
          <MyUser />
        </div>
      </div>
    </div>
  );
};

export default observer(Header);
