import projectImg from "@/assets/create-space/project.png";
import "./FolderCard.less";
interface IFolderCardProps {
  name: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const FolderCard: React.FC<IFolderCardProps> = (props) => {
  const { name, onClick, style } = props;
  return (
    <div
      className="file-upload-folder-folder-card-wrapper"
      style={style}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}>
      <div className="file-upload-folder-img-wrapper">
        <img src={projectImg} alt="" />
      </div>
      <p>{name}</p>
    </div>
  );
};

export default FolderCard;
