// CustomListItem.js
import { ListItem } from "@tiptap/extension-list-item";

const CustomListItem = ListItem.extend({
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const { state } = editor.view;
        const { $from } = state.selection;
        const node = $from.parent;

        // 检查当前节点是否为列表项
        // 检查列表项是否为空
        const isEmpty =
          node.content.size === 0 ||
          (node.content.firstChild.isText &&
            node.content.firstChild.text.trim() === "");
        if (isEmpty) {
          // 阻止默认的回车键行为
          return true;
        }

        // 允许默认的回车键行为
        return false;
      },
    };
  },
});

export default CustomListItem;
