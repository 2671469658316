const parseMovieGetCoverAndTime = (
  file: File,
): Promise<{ img: string; duration: number }> => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement("video");
    video.src = url;
    video.preload = "metadata";

    let isProcessed = false;
    let videoDuration = 0;

    const onLoadedMetadata = () => {
      videoDuration = video.duration;
      console.log("视频时长: ", videoDuration, "秒");
    };

    const onCanPlay = () => {
      if (!isProcessed) {
        video.currentTime = 0;
      }
    };

    const onSeeked = () => {
      if (!isProcessed) {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context?.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (blob) {
            const imgUrl = URL.createObjectURL(blob);
            console.log("封面图 URL: ", imgUrl);

            isProcessed = true;

            cleanup();
            resolve({ img: imgUrl, duration: videoDuration });
          } else {
            reject("无法生成封面图");
          }
        }, "image/jpeg");
      }
    };

    const cleanup = () => {
      URL.revokeObjectURL(url);
      video.removeEventListener("loadedmetadata", onLoadedMetadata);
      video.removeEventListener("canplay", onCanPlay);
      video.removeEventListener("seeked", onSeeked);
      video.src = "";
    };

    video.addEventListener("loadedmetadata", onLoadedMetadata, { once: true });
    video.addEventListener("canplay", onCanPlay, { once: true });
    video.addEventListener("seeked", onSeeked, { once: true });

    video.load();
  });
};

export default parseMovieGetCoverAndTime;
