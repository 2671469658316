import React from "react";
import { Tooltip } from "@arco-design/web-react";
import { IconExclamationCircle } from "@arco-design/web-react/icon";
import "./index.less";
interface IContentHeader {
  name: string | React.ReactNode;
  subName?: string | React.ReactNode;
  desc?: string;
  Lebtn?: React.ReactNode;
}
const ContentHeader: React.FC<IContentHeader> = (props) => {
  const { name, subName, desc, Lebtn } = props;
  return (
    <div className="header-container-root">
      <div className="header-container">
        <div>
          <div>
            <p className="header-title">
              <h1>{name}</h1>
              {desc ? (
                <Tooltip position="tl" content={desc}>
                  <IconExclamationCircle
                    width={14}
                    style={{ color: "white" }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </p>
            {subName ? <div className="sub-name">{subName}</div> : <></>}
          </div>
        </div>
        <>{Lebtn}</>
      </div>
    </div>
  );
};

export default ContentHeader;
