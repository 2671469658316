import { fileUploadStore } from "@/stores/UploadStroe";
import { Button, Message } from "@arco-design/web-react";
import { observer } from "mobx-react-lite";
import { Fragment, useRef, useState } from "react";
import LocalUploadCard from "./LocalUploadCard/LocalUploadCard";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import FolderCard from "../FolderCard/FolderCard";
import { v4 as uuid } from "uuid";
import Ton from "@/components/Hocicon";
import UploadButtonBg from "../UploadButtonBg/UploadButtonBg";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import "./LocalUpload.less";
interface LocalUpload {
  allowType: string;
  onChange: (e: boolean, item: IMaterialDetail) => void;
  collection: IMaterialDetail[];
  handleFolderItemClick: (item: IMaterialDetail) => void;
  data: IMaterialDetail[];
  projectId: number;
  currentFolderId: number | string;
  filterCondition?: (item: IMaterialDetail) => boolean;
}

const LocalUpload: React.FC<LocalUpload> = observer((props) => {
  const {
    allowType,
    onChange,
    collection,
    data,
    handleFolderItemClick,
    projectId,
    currentFolderId,
  } = props;
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [ids, setIds] = useState<string[]>([]);
  const handleFilesChange = () => {
    const groupName = `分组${fileUploadStore.uploadTasks.length > 0 ? fileUploadStore.uploadTasks.length : 0}`;
    const files = fileUploadRef.current?.files;
    const batchIds = uuid();
    setIds([...ids, batchIds]);
    if (files) {
      if (files.length > 100) {
        Message.error("最大限制100个文件数！");
        return;
      }
      const fileArray = Array.from(files);
      fileUploadStore.addUploadTasks(
        fileArray,
        Number(currentFolderId) as any,
        projectId,
        groupName,
        batchIds,
      );
      fileUploadRef.current.value = "";
    }
  };

  const handleUploadClick = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  return (
    <div className="file-upload-modal-local-upload">
      <input
        type="file"
        style={{ display: "none" }}
        accept={allowType}
        multiple={true}
        ref={fileUploadRef}
        onChange={handleFilesChange}
      />
      <div className="file-upload-modal-local-upload-container">
        <div
          className="Ton-wrapper"
          style={{
            width: "106px",
            height: "140px",
            marginRight: 16,
            marginBottom: 7,
          }}>
          {/* <Ton
            contel={}
            style={{
             
            }}>
            <Ton.Item>
              <Button
                onClick={handleUploadClick}
                type="outline"
                style={{ border: "none", background: "white" }}>
                上传文件
              </Button>
            </Ton.Item>
          </Ton> */}
          <UploadButtonBg
            style={{
              width: "106px",
              height: "112px",
              marginRight: 16,
              marginBottom: 7,
              background: "#f5f8ff",
            }}
            onClick={() => {
              handleUploadClick();
            }}
          />
        </div>
        {(data || []).map((item) => {
          return (
            <Fragment key={item.id}>
              <FolderCard
                style={{ marginRight: 16, marginBottom: 7 }}
                name={item.name}
                onClick={() => {
                  handleFolderItemClick(item);
                }}
              />
            </Fragment>
          );
        })}
        {(fileUploadStore?.uploadTasks || [])
          .filter((uploadTasks) => ids.includes(uploadTasks.id))
          .map((batch) =>
            batch.taskList
              .filter(
                (item) =>
                  item.folder_id === currentFolderId &&
                  item.project_id === projectId,
              )
              .map((task) => (
                <Fragment key={task.id}>
                  <LocalUploadCard
                    onChange={onChange}
                    checked={
                      collection.findIndex(
                        (step) => step?.id === task?.detailMaterial?.id,
                      ) > -1
                    }
                    item={task.detailMaterial}
                    cover={task.img}
                    type={task.type as CardTypeEnum}
                    error={task.error}
                    name={task.file.name}
                    state={task.status}
                    handleCloseBtnClick={() => {
                      fileUploadStore.cancelUploadTask(task.id);
                    }}
                    handleCencelBtnClick={() => {
                      fileUploadStore.cancelUploadTask(task.id);
                    }}
                    progress={task.progress}
                  />
                </Fragment>
              )),
          )}
      </div>
    </div>
  );
});

export default LocalUpload;
