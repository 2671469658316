import { forwardRef, useImperativeHandle, useRef } from "react";

interface IVideoPreviewProps {
  url: string;
}

const VideoPreview = forwardRef<HTMLVideoElement, IVideoPreviewProps>(
  (props, ref) => {
    const { url } = props;
    const videoRef = useRef<HTMLVideoElement>(null);

    // 使用 useImperativeHandle 将内部 videoRef 暴露出去
    useImperativeHandle(ref, () => videoRef.current as HTMLVideoElement, []);

    return (
      <>
        <video ref={videoRef} src={url} controls={false} muted></video>
      </>
    );
  },
);

export default VideoPreview;
