export enum Permissions {
  IntelligentEditing = "IntelligentEditing",
  MatrixMarketing = "MatrixMarketing",
  AIVideoAnalysis = "AIVideoAnalysis",
  MultiThemeScript = "MultiThemeScript",
  CreativeSpace = "CreativeSpace",
  ClientDownload = "ClientDownload",
  MaterialLibrary = "MaterialLibrary",
  CreativeEngineering = "CreativeEngineering",
  ScriptLibrary = "ScriptLibrary",
  HotScript = "HotScript",
  SampleReels = "SampleReels",
  AIThemeCreation = "AIThemeCreation",
  TaskCenter = "TaskCenter",
  UserCenter = "UserCenter",
  TeamManagement = "TeamManagement",
  DataIntegration = "DataIntegration",
  DataBoard = "DataBoard",
  TeamName = "TeamName",
  EntitlementCard = "EntitlementCard",
  SwitchingTeam = "SwitchingTeam",
  CreativeScript = "CreativeScript",
}

export type Permission = (typeof Permissions)[keyof typeof Permissions];

export enum Role {
  Director = "director",
  Editor = "editor",
  Operator = "operator",
  Viewer = "viewer",
  Superadmin = "superadmin",
  NoTeam = "noteam",
}
