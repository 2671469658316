import { Checkbox } from "@arco-design/web-react";
import { ReactNode } from "react";
import "./CheckBoxHoc.less";
export interface ICheckboxHocPros {
  checked: boolean;
  onCheckedChange: (state: boolean) => void;
  children?: ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const CheckBoxHoc: React.FC<ICheckboxHocPros> = (props) => {
  const { checked, onCheckedChange, children, style, disabled } = props;
  return (
    <div
      className={`checkbox-wrapper-hoc ${checked ? "active" : ""}`}
      style={style}>
      <Checkbox
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ display: `${checked ? "inline-block" : ""}` }}
        className={`checkbox-item `}
        checked={checked}
        onChange={onCheckedChange}></Checkbox>
      {children}
    </div>
  );
};

export default CheckBoxHoc;
