import { IconClose } from "@arco-design/web-react/icon";
import "./IconCloseBtn.less";
export interface IIconCloseBtnProps {
  handleCloseBtnClick();
  style?: React.CSSProperties;
  className?: string;
}

const IconCloseBtn: React.FC<IIconCloseBtnProps> = (props) => {
  const { handleCloseBtnClick, style, className } = props;
  return (
    <div
      className={`close-wrapper ${className}`}
      style={style}
      onClick={() => {
        handleCloseBtnClick();
      }}>
      <IconClose style={{ fontSize: 7 }} />
    </div>
  );
};

export default IconCloseBtn;
