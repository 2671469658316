/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from "react";
import Modals from "@/components/UI/Modals";
import { Button, Image, Input, Message } from "@arco-design/web-react";
import { disablemerber_icon } from "@/assets/taskcenter";

interface IChangeModalProps {
  visible: boolean;
  handleChangeMoadl: () => void;
  handleClick: () => void;
}

const ChangeModal: React.FC<IChangeModalProps> = ({
  visible,
  handleChangeMoadl,
  handleClick,
}) => {
  return (
    <Modals
      width={464}
      title="提示"
      cancelBtn={
        <Button
          onClick={() => {
            handleChangeMoadl();
          }}>
          取消
        </Button>
      }
      subMiteBtn={
        <Button
          type="primary"
          onClick={() => {
            if (handleClick) {
              handleClick();
            }
          }}>
          确认
        </Button>
      }
      titleIcon={<Image width={18} preview={false} src={disablemerber_icon} />}
      visible={visible}>
      <div className="user-create-team-moadl">
        <p
          style={{
            fontSize: 16,
            fontFamily: "PingFang SC, PingFang SC",
            fontWeight: 500,
            marginTop: 15,
          }}>
          确认离开当前页面?
        </p>
        <p
          style={{
            fontSize: 16,
            fontFamily: "PingFang SC, PingFang SC",
            fontWeight: 500,
          }}>
          离开后会导致正在执行的任务中断
        </p>
      </div>
    </Modals>
  );
};

export default ChangeModal;
