/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Fragment, ReactNode, SyntheticEvent } from "react";
import { rolePermissionsMap } from "./rolePermissionsMap";
import { Message } from "@arco-design/web-react";
import User from "@/stores/userStore";
import _ from "lodash";
import { observer } from "mobx-react";

interface RolePermissionProps {
  requiredPermissions: string; // 所需权限列表
  children: ReactNode; // 子元素
  mixture?: boolean; //
}

interface UnclickableProps extends RolePermissionProps {
  //
}
// 辅助函数：重写所有事件处理器
const EVENT_PROPS = [
  "onClick",
  // "onDoubleClick",
  // "onMouseDown",
  // "onMouseUp",
  // "onMouseEnter",
  // "onTouchStart",
  // "onTouchEnd",
  // "onTouchMove",
  // "onKeyDown",
  // "onKeyUp",
] as const;

export const setUserPermissions = (roles: string[]): Permissions[] => {
  const permissionsSet = new Set<Permissions>();
  roles.forEach((role) => {
    const perms = rolePermissionsMap[role];
    if (!perms || !perms.length) {
      return;
    }
    perms.forEach((perm) => permissionsSet.add(perm));
  });

  return Array.from(permissionsSet);
};

export const getUserPermissions = (requiredPermissions) => {
  const mergedPermissions = _.get(
    User,
    "teamInfo.team_user_role.team.name",
    null,
  )
    ? setUserPermissions(
        (
          User.teamInfo?.team_user_role?.roles || [
            {
              code: "viewer",
            },
          ]
        ).map((v) => v.code),
      )
    : [
        {
          code: "noteam",
        },
      ];

  return mergedPermissions.includes(requiredPermissions);
};

const UnFun = (requiredPermissions, children) => {
  const hasPermission = getUserPermissions(requiredPermissions);
  // 确保 children 是一个 ReactElement，并且包含 onClick 属性
  if (!React.isValidElement(children)) {
    return <></>;
  }
  // 事件拦截处理函数
  const preventEvent = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const res = await User.getTeamStatus();
    console.log(res);

    if (!res) {
      Message.warning("暂无权限");
    }
  };
  // 如果用户没有权限，重写 onClick 事件
  if (!hasPermission && requiredPermissions != undefined) {
    const captureHandlers = EVENT_PROPS.reduce(
      (handlers, prop) => {
        const reactEventProp = prop + "Capture";
        handlers[reactEventProp] = preventEvent;
        return handlers;
      },
      {} as { [key: string]: (e: SyntheticEvent) => void },
    );

    // 包裹子元素，并添加捕获阶段的事件处理器
    return (
      <div
        {...captureHandlers}
        style={{
          display: "contents", // 不影响布局
        }}>
        {children}
      </div>
    );
  }

  // 如果用户有权限，正常渲染
  return children;
};

const roleFun = (requiredPermissions, children) => {
  const hasPermission = getUserPermissions(requiredPermissions);
  if (hasPermission || requiredPermissions == undefined) {
    return <>{children}</>;
  }
  return null;
};

const RolePermission = {
  // ShowHide 子组件
  ShowHide: observer(
    ({ requiredPermissions, children, mixture }: RolePermissionProps) => {
      return mixture
        ? UnFun(requiredPermissions, children)
        : roleFun(requiredPermissions, children);
    },
  ),
  // Unclickable 子组件
  Unclickable: observer(
    ({ requiredPermissions, children, mixture }: UnclickableProps) => {
      return mixture
        ? roleFun(requiredPermissions, children)
        : UnFun(requiredPermissions, children);
    },
  ),
};

export default RolePermission;
