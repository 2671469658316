/* eslint-disable no-unused-expressions */
import { useEffect, useRef, useState } from "react";
import { createPlayer } from "./playerUtil";
import { Slider } from "@arco-design/web-react";
// import { IconSound, IconMute } from "@arco-design/web-react/icon";
import vodeoplayback from "@/assets/videoparsing/vodeoplayback.png";
// import videofullscreen from "@/assets/videoparsing/videoFullSCREEN.png";
import { Subscription } from "rxjs";
import "./index.less";

const TAG = "Player";

const options = ["9:16", "16:9", "3:4", "4:3", "1:1"];

function Player(props) {
  const {
    previewInfo,
    PlayerFunRef = null,
    isDisabled = true,
    clipWatch = null,
    clipId,
    aspectRatio,
    autoPlay = false,
  } = props;

  // const [aspectRatio, setAspectRatio] = useState("9:16");
  const [size, setSize] = useState({ w: 360, h: 640 });
  const playerRef = useRef(null);
  const containerRef = useRef(null);

  // 播放状态和进度状态
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false); // 标识是否正在拖拽进度条
  const [volume, setVolume] = useState(1); // 默认音量为100%
  const timer = useRef(null);
  const [previousVolume, setPreviousVolume] = useState(1); // 记录之前的音量，用于恢复
  const watcher = useRef({});
  const isMuted = volume === 0;

  // 动态加载脚本的函数
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      // 检查脚本是否已经存在
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = src;
      script.async = true;

      script.onload = () => {
        console.log(`脚本 ${src} 加载完成`);
        resolve();
      };

      script.onerror = () => {
        console.error(`脚本 ${src} 加载失败`);
        reject(new Error(`脚本 ${src} 加载失败`));
      };

      document.head.appendChild(script);
    });
  };

  const loadAssetsAndInit = async () => {
    await Promise.all([
      loadScript(
        "https://g.alicdn.com/thor-server/video-editing-websdk/5.2.2/player.js",
      ),
    ]);
  };

  useEffect(() => {
    let isMounted = true; // 防止组件卸载后仍尝试更新
    if (!containerRef.current) return;
    if (!isMounted) return; // 组件已卸载，停止执行
    const initializePlayer = async () => {
      try {
        // 动态加载脚本
        await loadAssetsAndInit();

        // 确保 AliyunTimelinePlayer 已经存在
        if (!window.AliyunTimelinePlayer) {
          console.error("AliyunTimelinePlayer 未加载成功");
          return;
        }

        // 创建播放器
        const player = createPlayer({
          container: containerRef.current,
          minWidth: size.w,
        });
        playerRef.current = player;

        const subscription = new Subscription();
        const eventSubscription = player.event$.subscribe((event) => {
          switch (event.type) {
            case "play":
              setIsPlaying(true);
              break;
            case "pause":
              setIsPlaying(false);
              break;
            case "render":
              setProgress(player.currentTime);
              setDuration(player.duration);
              break;
            case "playerStateChange":
              if (player.currentTime === player.duration) {
                setIsPlaying(false);
              }
              break;
            default:
              break;
          }
        });

        subscription.add(eventSubscription);

        // 初始化音量
        player.volume = volume;

        // 保存订阅到引用中，以便在清理时使用
        playerRef.current.subscription = subscription;
      } catch (error) {
        console.error(
          "Failed to load player script or initialize player:",
          error,
        );
      }
    };

    initializePlayer();

    return () => {
      // 清理订阅和销毁播放器
      if (playerRef.current) {
        if (playerRef.current.subscription) {
          playerRef.current.subscription.unsubscribe();
        }
        playerRef.current.destroy();
      }
      isMounted = false;
    };
  }, [containerRef, size, isSeeking]);

  // 监听音量变化，更新播放器音量
  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.volume = volume;
    }
  }, [volume]);

  const onSubmitPreview = (preview, aspectRatio, size) => {
    if (!preview?.timeline || !playerRef?.current?.setTimeline) {
      return;
    }
    let timeline;
    playerRef.current.setTimeline(null);
    try {
      timeline = preview.timeline;
    } catch (e) {
      alert("时间轴格式错误: " + e.message);
      return;
    }
    const timelineWrap = Object.assign(
      {
        Version: 1,
        AspectRatio: aspectRatio,
        From: "websdk",
        FECanvas: { Width: size.w, Height: size.h },
      },
      timeline,
    );
    Object.keys(watcher.current).forEach((v) => {
      console.log(TAG, "submitPreview+", watcher.current[v]);
      const unwatch = watcher.current[v];
      unwatch && unwatch();
    });
    watcher.current = {};
    try {
      const parsedData = window.AliyunTimelinePlayer.parseTimeline(
        timelineWrap,
        {
          outputWidth: timelineWrap.FECanvas.Width,
          outputHeight: timelineWrap.FECanvas.Height,
        },
      );
      playerRef.current.setTimeline(parsedData.timeline); // 假设 parseTimeline 返回同步数据
      // playerRef.current.watchTrack((tracks) => {
      //   console.log("watchTrack: ", tracks);
      // });
      if (autoPlay) {
        setTimeout(() => {
          togglePlayPause();
        }, 500);
      }
    } catch (error) {
      console.error("解析时间轴失败:", error);
    }
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      if (progress === duration) {
        playerRef.current.currentTime = 0;
        playerRef.current.play();
      }
      playerRef.current.play();
    }
  };

  const onChangeAspectRatio = () => {
    if (!aspectRatio) return;
    let nSize = { ...size };
    switch (aspectRatio) {
      case "9:16":
        nSize = { w: 360, h: 640 };
        break;
      case "16:9":
        nSize = { w: 640, h: 360 };
        break;
      case "3:4":
        nSize = { w: 360, h: 480 };
        break;
      case "4:3":
        nSize = { w: 480, h: 360 };
        break;
      case "1:1":
        nSize = { w: 480, h: 480 };
        break;
      default:
        break;
    }
    setSize(nSize);
    if (containerRef.current) {
      console.log("containerRef 进来");
      const scale = 360 / nSize.w;
      containerRef.current.style.setProperty("transform", `scale(${scale})`);
      setTimeout(() => {
        onSubmitPreview(previewInfo, aspectRatio, nSize);
      }, 500);
    }
  };

  useEffect(() => {
    onChangeAspectRatio();
  }, [aspectRatio]);

  // 处理进度条变化
  const handleProgressChange = (value) => {
    setProgress(value);
    // playerRef.current.play();
  };

  const handleAfterChange = (value) => {
    setIsSeeking(false);
    playerRef.current.currentTime = value;
  };

  const handleBeforeChange = () => {
    setIsSeeking(true);
  };

  // 辅助函数，用于格式化时间
  function formatTime(seconds) {
    if (isNaN(seconds)) return "00:00";
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${padZero(secs)}`;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  // 处理滑块变化
  const handleVolumeChange = (value) => {
    if (value < 0) value = 0;
    setVolume(value);
    if (value > 0) {
      setPreviousVolume(value);
    }
  };

  const onRemoveClip = (clipId) => {
    const unwatch = watcher.current[clipId];
    unwatch && unwatch();
    delete watcher.current[clipId];
  };

  const onFocusClip = (clipId) => {
    if (!Object.keys(watcher.current).some((v) => v === clipId)) {
      const unwatch = playerRef.current?.watchClip(
        Number(clipId),
        (vConfig) => {
          clipWatch && clipWatch(clipId, vConfig);
        },
      );
      watcher.current[clipId] = unwatch;
    }
    playerRef.current?.focusClip(Number(clipId));
  };

  // 切换静音状态
  const toggleMute = () => {
    if (isMuted) {
      // 恢复之前的音量
      setVolume(previousVolume > 0 ? previousVolume : 1);
    } else {
      // 静音，并记录之前的音量
      setPreviousVolume(volume);
      setVolume(0);
    }
  };

  const audioControl = () => {
    return (
      <div className="audio-control-box">
        <Slider
          vertical
          value={volume}
          style={{ height: "100%" }}
          onChange={handleVolumeChange}
        />
      </div>
    );
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      onSubmitPreview(previewInfo, aspectRatio, size);
    }, 1500);
    return () => clearTimeout(timer.current);
  }, [previewInfo]);

  useEffect(() => {
    if (PlayerFunRef && PlayerFunRef.current) {
      PlayerFunRef.current = {
        aspectRatio,
        onChangeAspectRatio,
        aspectRatioOptions: options,
        onRemoveClip,
      };
    }
  }, [PlayerFunRef]);

  useEffect(() => {
    if (clipId) {
      setTimeout(() => {
        onFocusClip(clipId);
      }, 300);
    } else {
      playerRef?.current?.blurClip();
    }
  }, [clipId]);

  return (
    <div className="Player">
      <div className="preview">
        <div
          ref={containerRef}
          style={{ width: size.w, height: size.h }}
          onClick={() => {
            playerRef.current.pause();
          }}
        />
        {!isPlaying && !isDisabled ? (
          <img
            className="play-btn"
            src={vodeoplayback}
            onClick={togglePlayPause}
            alt=""
          />
        ) : (
          <></>
        )}
        {!isDisabled ? (
          <div className="video-controlsprogress-slide">
            <div
              className="video-progress-slider"
              style={{ position: "relative" }}>
              <Slider
                max={duration}
                tooltipVisible={false}
                tooltip={{ visible: false }}
                value={progress}
                onChange={handleProgressChange}
                onAfterChange={handleAfterChange}
                onBeforeChange={handleBeforeChange}
                style={{ width: 200 }}
              />
            </div>
            <div className="format-time">
              <div>
                <span>{formatTime(progress)}</span>/
                <span>{formatTime(duration)}</span>
              </div>
              <div className="format-tool">
                {/* <Popover content={audioControl()}>
                <div className="volume-control">
                  {isMuted ? (
                    <IconMute onClick={toggleMute} fontSize={16} />
                  ) : (
                    <IconSound onClick={toggleMute} fontSize={16} />
                  )}
                </div>
              </Popover> */}
                {/* <img
                src={videofullscreen}
                style={{ marginLeft: 15 }}
                width={16}
                alt=""
              /> */}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Player;
