import React, { useState, useRef, useEffect } from "react";
import { IconCaretDown, IconCaretUp } from "@arco-design/web-react/icon";
import "./index.less"; // 引入CSS样式

const Collapse = ({ children, isShow, hiddenHeight = 102 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);
  const [height, setHeight] = useState(0);
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setHeight(isOpen ? contentRef.current.scrollHeight : hiddenHeight);
  }, [isOpen]);
  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [isShow]);
  return (
    <div className="collapse-root">
      <div
        ref={contentRef}
        style={{ maxHeight: height }}
        className="collapse-content">
        {children}
      </div>
      <div className="collapse-btn">
        <div></div>
        {!isOpen ? (
          <div onClick={toggleCollapse}>
            展开
            <IconCaretDown />
          </div>
        ) : (
          <div onClick={toggleCollapse}>
            收起
            <IconCaretUp />
          </div>
        )}
        <div></div>
      </div>
    </div>
  );
};

export default Collapse;
