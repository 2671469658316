import React, { ReactNode, ReactElement } from "react";
import { Modal } from "@arco-design/web-react";
import "./index.less";

interface IModalProps {
  width?: number | string;
  height?: number | string;
  title: string;
  children: ReactNode;
  titleIcon?: ReactElement;
  closeIcon?: ReactElement;
  visible?: boolean;
  cancelBtn?: ReactElement;
  subMiteBtn?: ReactElement;
  style?: React.CSSProperties;
}

const Modals: React.FC<IModalProps> = (props) => {
  const {
    width,
    height,
    title,
    children,
    titleIcon,
    closeIcon,
    visible,
    cancelBtn,
    style = {},
    subMiteBtn,
    ...item
  } = props;
  return (
    <Modal
      title={null}
      style={{ width: width, height: height, ...style }}
      footer={null}
      closable={false}
      focusLock={false}
      autoFocus={false}
      visible={visible}
      {...item}>
      <div className="creation-modal">
        <div className="modal-header">
          <div className="moadl-title">
            <div>
              {titleIcon}
              <span>{title}</span>
            </div>
            <div>{closeIcon}</div>
          </div>
        </div>
        <div>{children}</div>
        <div className="modal-footer">
          {cancelBtn}
          {subMiteBtn}
        </div>
      </div>
    </Modal>
  );
};

export default Modals;
