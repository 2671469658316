import addImg from "@/assets/create-space/add-dark.png";
import tipImg from "@/assets/create-space/tip.png";
import "./UploadButtonBg.less";
import { Tooltip } from "@arco-design/web-react";

interface IUploadButtonBg {
  style?: React.CSSProperties;
  className?: string;
  tooltip?: string;
  onClick?: () => void;
}

const UploadButtonBg: React.FC<IUploadButtonBg> = (props) => {
  const { style, className, tooltip, onClick } = props;
  return (
    <div
      className={`material-upload-block-bg ${className}`}
      onClick={() => {
        onClick();
      }}
      style={style}>
      <img src={addImg} alt="" className="add-img" />
      <p>
        上传素材
        <Tooltip
          content={
            tooltip ? (
              tooltip
            ) : (
              <div style={{ width: "150px", fontSize: "10px" }}>
                支持上传mov、mp4、mp3、jpg、png、gif、jpeg格式，单个文件大小不超过5GB
              </div>
            )
          }>
          <img
            src={tipImg}
            alt=""
            className="tip-img"
            style={{ marginLeft: "4px" }}
          />
        </Tooltip>
      </p>
    </div>
  );
};

export default UploadButtonBg;
