/* eslint-disable no-constant-condition */
import React, { useEffect, useRef, useState } from "react";
import { Image, Button, Message, Tooltip } from "@arco-design/web-react";
import { IconClose } from "@arco-design/web-react/icon";
import {
  abbreviation_w_icon,
  expand_w_icon,
  rewrite_icon,
  smart_icon,
  helpWrite_icon,
  start_icon,
  stop_icon,
  loading_gif,
  reset_icon,
} from "@/assets/creationscriptimage";
import "./index.less";
import DocumentText from "./component/DocumentText";
import { getScriptCopywriter } from "@/services/creativeScript/explosiveScript";
import LoadingGIF from "@/components/UI/LoadingGIF";
interface TextObject {
  key: string;
  strings: string[];
}
interface IRenderClick {
  type: string;
  text: string;
}
interface IIntelligent {
  width?: number;
  /**
   * defaultStatus 右侧tab默认使用状态 由外部控制
   */
  defaultStatus: string;
  /**
   * value 原语句
   */
  value: string;
  style?: React.CSSProperties;
  /**
   * handleOnChange 卡片工具点击事件 返回类型和内容
   */
  handleOnChange: (opt: IRenderClick) => void;
  /**
   * isInExecution 是否正在打印中
   */
  isInExecution?: (opt: boolean) => void;
  /**
   * 关闭事件
   */
  handleCloseBtnClick?: () => void;
  /**
   * 文案内容样式
   */
  contentStyle?: React.CSSProperties;
}

const Intelligent: React.FC<IIntelligent> = ({
  defaultStatus,
  value,
  style,
  handleOnChange,
  isInExecution,
  handleCloseBtnClick,
  contentStyle,
}) => {
  const [toolBtn, setToolBtn] = useState([
    { icon: rewrite_icon, text: "改写", status: false, value: 1 },
    { icon: expand_w_icon, text: "扩写", status: false, value: 2 },
    { icon: abbreviation_w_icon, text: "缩写", status: false, value: 3 },
    { icon: helpWrite_icon, text: "帮写", status: false, value: 4 },
  ]);
  const [textArr, setTextArr] = useState<TextObject[]>([]);
  const [isloading, setIsloading] = useState(false);
  const isloadingRef = useRef(null);
  const [isUnderway, setIsUnderway] = useState(false);
  const [isStart, setIsStart] = useState(true);
  const [rederProps, setRederProps] = useState<any>();
  const [status, setStatus] = useState("");
  const [hoverStatus, setHoverStatus] = useState<boolean>(false);
  const controllerRef = useRef(new AbortController());
  const signalRef = useRef(controllerRef.current.signal);

  // 设置右侧按钮状态
  const getIntelliCopyWriterFun = async (text) => {
    setIsStart(true);
    setIsUnderway(true);
    setIsloading(true);
    const res = await getScriptCopywriter(
      {
        action: toolBtn.find((v) => v.text == text)?.value, // 1:修改|2:扩|3:缩|4:帮
        text: value.replace(/\n/g, ""), // 原语句
      },
      signalRef.current,
    );

    setIsloading(false);
    if (res) {
      if (!isloadingRef.current) {
        setIsStart(false);
        setIsloading(false);
        setIsUnderway(false);
      } else {
        setTextArr(res.data);
      }
    }
  };
  useEffect(() => {
    isloadingRef.current = isStart;
  }, [isStart]);
  useEffect(() => {
    if (defaultStatus) {
      controllerRef.current = new AbortController();
      signalRef.current = controllerRef.current.signal;
      getIntelliCopyWriterFun(defaultStatus);
      setStatus(defaultStatus);
    }
  }, [defaultStatus, value]);

  useEffect(() => {
    setToolBtn(
      toolBtn.map((v) => ({ ...v, status: v.text == status ? true : false })),
    );
  }, [status]);
  useEffect(() => {
    if (isInExecution) {
      isInExecution(!isUnderway);
    }
  }, [rederProps?.isEnd, isloading, isUnderway]);

  return (
    <div
      className="intelligent-root"
      style={{
        width: 432,
        ...style,
      }}>
      <div className="intelligent-box">
        <div className="intelligent-header">
          <Image
            src={smart_icon}
            width={106}
            preview={false}
            style={{ marginTop: "-4px" }}
          />
          <IconClose
            onClick={() => {
              if (handleCloseBtnClick) {
                handleCloseBtnClick();
              }
              setToolBtn(toolBtn.map((v) => ({ ...v, status: false })));
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="intelligent-content">
          <div className="intelligent-left" style={contentStyle || {}}>
            {value ? (
              <div className="original-box">
                <div className="original-corner-mark">原语句</div>
                <table></table>
                <div className="original-text"> {value}</div>
                <table></table>
              </div>
            ) : (
              <></>
            )}
            {!isloading ? (
              <DocumentText
                underway={setIsUnderway}
                btnStatus={status == "帮写" ? "change" : ""}
                data={textArr}
                renderClick={(opt) => {
                  if (!location) {
                    Message.error("无法定位到内容!");
                    return;
                  }
                  handleOnChange(opt);
                }}
                renderProps={(opt) => {
                  setRederProps(opt);
                }}
              />
            ) : (
              <></>
            )}
            <div
              className="typed-tool"
              style={{
                display:
                  (!isloadingRef.current ||
                    rederProps?.isEnd >= textArr.length) &&
                  !isloading
                    ? "none"
                    : "flex",
              }}>
              <div
                className="typed-loading"
                style={{ opacity: isloadingRef.current ? 1 : 0 }}>
                {/* <Image src={loading_gif} width={74} preview={false} /> */}
                <LoadingGIF />
                {/* 正在生成，请稍后... */}
              </div>
              <Tooltip content="停止生成" position="right">
                <Image
                  onMouseEnter={() => setHoverStatus(true)}
                  onMouseLeave={() => setHoverStatus(false)}
                  src={hoverStatus ? stop_icon : start_icon}
                  width={20}
                  preview={false}
                  style={{ marginLeft: 14 }}
                  onClick={() => {
                    rederProps.handleStopAll();
                    setIsStart(false);
                    setIsUnderway(false);
                    if (isloading) {
                      setTextArr([]);
                      setIsloading(false);
                    }
                    controllerRef.current.abort();
                  }}
                />
              </Tooltip>
            </div>

            <div
              className="typed-reset-box"
              style={{
                display:
                  (rederProps?.isEnd >= textArr.length && !isloading) ||
                  !isloadingRef.current
                    ? "block"
                    : "none",
              }}>
              <Button
                type="primary"
                className="typed-reset"
                icon={
                  <Image
                    src={reset_icon}
                    preview={false}
                    width={16}
                    style={{ marginTop: -6, marginRight: 8 }}
                  />
                }
                onClick={() => {
                  controllerRef.current = new AbortController();
                  signalRef.current = controllerRef.current.signal;
                  getIntelliCopyWriterFun(status);
                }}>
                重新生成
              </Button>
            </div>
          </div>
          <div className="intelligent-right">
            {toolBtn.map((item, index) => (
              <div
                key={index}
                className="intelligent-tab-box"
                onClick={() => {
                  if (!isUnderway) {
                    controllerRef.current = new AbortController();
                    signalRef.current = controllerRef.current.signal;
                    setToolBtn(
                      toolBtn.map((v) => ({
                        ...v,
                        status: v.text == item.text ? true : false,
                      })),
                    );
                    setStatus(item.text);
                    getIntelliCopyWriterFun(item.text);
                  } else {
                    Message.warning("正在生成中, 请稍后...");
                  }
                }}>
                <div
                  className="intelligent-tab"
                  style={{
                    background: item.status
                      ? "linear-gradient(216deg, #cb75fc 0%, #7700ff 100%)"
                      : "#E6E8FA",
                  }}>
                  <Image
                    style={{ marginTop: "-3px" }}
                    src={item.icon}
                    width={18}
                    preview={false}
                  />
                </div>
                {item.text}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intelligent;
