/* eslint-disable no-case-declarations */

// move createPlayer to playerUtil.js or keep it here as per your project structure
export function createPlayer({ container, minWidth }) {
  console.log("创建一个player!", minWidth);
  return new window.AliyunTimelinePlayer({
    container: container,
    licenseConfig: {
      rootDomain: "zxaigc.com",
      licenseKey: "zPtL9R8wGPumTmq2C434ab2bc0b5341ea8f7703afef6793a8",
    },
    getMediaInfo: async (mediaId, mediaType, mediaOri) => {
      console.log(">>>", mediaId, mediaType, mediaOri);
      switch (mediaOri) {
        case "mediaId":
          return mediaId;
        case "mediaURL":
          return mediaId;
        // case "public":
        // break;
        default:
          console.log("查询mediaId:", mediaId);
          const body = await fetch(
            "/api/v1/space/media/info/play?media_id=" + mediaId,
          ).then((res) => res.json());
          console.log(body);
          return body["data"]["play_info_list"][0]["play_url"];
      }
    },
    minWidth: minWidth,
  });
}
