import { Role, Permission, Permissions } from "./permissions";

// 权限映射表
export const rolePermissionsMap: Record<Role, Permissions[]> = {
  [Role.Director]: [
    Permissions.AIVideoAnalysis,
    Permissions.MultiThemeScript,
    Permissions.CreativeSpace,
    Permissions.MaterialLibrary,
    Permissions.ScriptLibrary,
    Permissions.HotScript,
    Permissions.AIThemeCreation,
    Permissions.TaskCenter,
    Permissions.UserCenter,
    Permissions.DataIntegration,
    Permissions.DataBoard,
    Permissions.TeamName,
    Permissions.EntitlementCard,
    Permissions.SwitchingTeam,
    Permissions.SampleReels,
    Permissions.CreativeScript,
  ],
  [Role.Editor]: [
    Permissions.IntelligentEditing,
    Permissions.AIVideoAnalysis,
    Permissions.MultiThemeScript,
    Permissions.CreativeSpace,
    Permissions.MaterialLibrary,
    Permissions.CreativeEngineering,
    Permissions.ScriptLibrary,
    Permissions.SampleReels,
    Permissions.HotScript,
    Permissions.AIThemeCreation,
    Permissions.TaskCenter,
    Permissions.UserCenter,
    Permissions.DataIntegration,
    Permissions.DataBoard,
    Permissions.TeamName,
    Permissions.EntitlementCard,
    Permissions.SwitchingTeam,
    Permissions.SampleReels,
    Permissions.CreativeScript,
  ],
  [Role.Operator]: [
    Permissions.MatrixMarketing,
    Permissions.CreativeSpace,
    Permissions.ClientDownload,
    Permissions.UserCenter,
    Permissions.DataIntegration,
    Permissions.DataBoard,
    Permissions.TeamName,
    Permissions.EntitlementCard,
    Permissions.SwitchingTeam,
    Permissions.SampleReels,
    Permissions.MaterialLibrary,
    Permissions.TaskCenter,
  ],
  [Role.Viewer]: [Permissions.UserCenter],
  [Role.Superadmin]: [
    Permissions.IntelligentEditing,
    Permissions.MatrixMarketing,
    Permissions.AIVideoAnalysis,
    Permissions.MultiThemeScript,
    Permissions.CreativeSpace,
    Permissions.ClientDownload,
    Permissions.MaterialLibrary,
    Permissions.CreativeEngineering,
    Permissions.ScriptLibrary,
    Permissions.HotScript,
    Permissions.AIThemeCreation,
    Permissions.TeamManagement,
    Permissions.TaskCenter,
    Permissions.UserCenter,
    Permissions.DataIntegration,
    Permissions.DataBoard,
    Permissions.TeamName,
    Permissions.EntitlementCard,
    Permissions.SwitchingTeam,
    Permissions.SampleReels,
    Permissions.CreativeScript,
  ],
  [Role.NoTeam]: [Permissions.UserCenter],
};
