import React from "react";
import { Button, ButtonProps } from "@arco-design/web-react";
import "./VLButton.less";

const VoLongButton: React.FC<ButtonProps> = (props) => {
  const { type, ...res } = props;
  return (
    <Button
      {...res}
      className={`${type === "primary" ? "volong-primary-button" : ""} ${res.className}`}
      type={type}></Button>
  );
};

export default VoLongButton;
