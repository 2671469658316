/* eslint-disable prefer-const */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import StarterKit from "@tiptap/starter-kit";
import { observer } from "mobx-react";
import {
  useEditor,
  EditorContent,
  Extensions,
  JSONContent,
} from "@tiptap/react";
import CharacterCount from "./component/CharacterCount";
import Placeholder from "@tiptap/extension-placeholder";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import { BreakTag } from "./component/break/";
import { useEditorHelper } from "./helper";
import CustomListItem from "./component/CustomListItem";
import { Schema } from "prosemirror-model";
import HighlightRangeExtension, {
  highlightRangePluginKey,
} from "./component/Highlight";
import "./index.less";
interface IHighlightRange {
  from: number;
  to: number;
  color: string;
}
interface SelectionInfo {
  value: string;
  range: {
    from: number;
    to: number;
  };
}

interface IMyEditorProps {
  content: any;
  handlefocus: () => void;
  handleblur?: () => void;
  sendInfo?: () => void;
  selected?: (selection: SelectionInfo) => void;
  type: string;
  selectInfo: any;
  limit?: number;
  cursor?: any;
  setEditPosition?: (position: { from: number; to: number }) => void;
  handleUpdate: (value: JSONContent[] | any[]) => void;
  editorCurrentStyle?: React.CSSProperties;
  limitPosition?: React.CSSProperties;
  synergy?: boolean;
  useCharacterLimit?: boolean;
  placeholder?: string;
  index?: number;
  ref: any;
}

const MyEditor = forwardRef((props: IMyEditorProps, ref) => {
  const {
    content,
    handlefocus,
    sendInfo,
    selected,
    type,
    selectInfo,
    limit,
    handleblur,
    cursor,
    setEditPosition,
    handleUpdate,
    editorCurrentStyle,
    limitPosition = {},
    synergy = false,
    useCharacterLimit = true,
  } = props;

  // 文本长度
  const [textLength, setTextLength] = useState(0);
  const [isStart, setiIsStart] = useState(false);

  const fasRef = useRef<any>();

  const extensions: Extensions = [
    StarterKit,
    BreakTag,
    ...(useCharacterLimit
      ? [
          CharacterCount.configure({
            limit,
            mode: "textSize",
            textLength,
            cursorPositionInfo: cursor?.userInfo?.length ? cursor : null,
          }),
        ]
      : []),
    Placeholder.configure({
      placeholder: props.placeholder, // 占位符文本
    }),
    BulletList, // 启用项目符号列表
    OrderedList, // 启用有序列表（可选）
    CustomListItem, // 使用自定义的 ListItem
    // HighlightRangeExtension,
  ];

  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);

  const editor = useEditor({
    extensions,
    enableCoreExtensions: true,
    onFocus() {
      handlefocus();
      setShowPlaceholder(true);
    },
    onBlur() {
      setShowPlaceholder(false);
      setiIsStart(false);
      if (handleblur) {
        handleblur();
      }
    },
    onUpdate: (e) => {
      if (!editor) return;
      setiIsStart(true);
      const editLength = editor.getText().replace(/\n/g, "").length;
      setTextLength(editLength);
      selected({
        ...selectInfo,
        value: editor
          .getText()
          .replace(/\n\n\n\n/g, "\n")
          .slice(1),
        range: helper.getSelectionSSML(),
      });

      const json = editor.getJSON().content[0]?.type;
      const value =
        json !== "bulletList"
          ? editLength
            ? content
            : [
                {
                  key: "0",
                  type: "listItem",
                  content: [
                    {
                      type: "paragraph",
                    },
                  ],
                },
              ]
          : editor.getJSON().content[0]?.content || [];

      const p = editor.getJSON().content[0]?.content;
      const textValue =
        p && p.length
          ? editor.getJSON().content
          : [
              {
                key: "0",
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text: "",
                  },
                ],
              },
            ];
      handleUpdate(content[0]?.type === "paragraph" ? textValue : value);

      if (json !== "bulletList" && content[0]?.type !== "paragraph") {
        updata();
      }
      fasRef.current = editor.getJSON().content;
    },
    onSelectionUpdate: (e) => {
      if (!e.editor.isFocused) {
        return;
      }
      setiIsStart(true);
      if (setEditPosition && editor) {
        let { from, to } = helper.getSelectionSSML();
        if (content[0]?.type !== "paragraph") {
          from -= 2;
          to -= 2;
        }
        setEditPosition({
          from,
          to,
        });
      }

      const selection = window.getSelection();
      const selectionValue = selection?.toString() || "";
      if (selectionValue) {
        selected({
          ...selectInfo,
          value: selectionValue.replace(/\n\n/g, "\n"),
          range: helper.getSelectionSSML(),
        });
      } else {
        selected({
          ...selectInfo,
          value: editor
            .getText()
            .replace(/\n\n\n\n/g, "\n")
            .slice(1),
          range: helper.getSelectionSSML(),
        });
      }
    },
  });

  const [helper] = useEditorHelper(editor);

  const updata = () => {
    if (!editor) return;
    editor.commands.setContent({
      type: content[0]?.type === "paragraph" ? "doc" : "bulletList",
      content,
    });
    if (sendInfo) {
      sendInfo();
    }
  };

  const addBreak = (config: { time?: string } = {}) => {
    const { time = "0.5s" } = config;
    if (!editor) {
      return;
    }
    const { from, to } = selectInfo.range;
    if (from !== to) {
      return;
    }
    const addBreakTag = () => {
      (editor.chain().setTextSelection(selectInfo.range) as any)
        .setBreakTime(time)
        .run();
    };
    addBreakTag();
  };

  useImperativeHandle(ref, () => ({
    editor,
    helper,
    addBreak,
    export() {
      return {
        text: helper.exportText(),
        json: helper.exportJSON(),
        ssml: helper.exportSSML(),
      };
    },
  }));
  // 监听 cursor 的变化并更新光标位置
  useEffect(() => {
    if (useCharacterLimit && editor && editor.storage.characterCount) {
      let cursorPositions = cursor?.userInfo?.length ? cursor.userInfo : [];
      if (type === "caption") {
        cursorPositions = cursorPositions.map((v) => ({
          ...v,
          position: v.position + 2,
          range: { from: v.range.from + 2, to: v.range.to + 2 },
        }));
      }
      editor.storage.characterCount.setCursorPositions(cursorPositions);
    }
  }, [cursor, useCharacterLimit, editor, type]);

  useEffect(() => {
    if (editor && !isStart) {
      const editLength = editor.getText().replace(/\n/g, "").length;
      setTextLength(editLength);
      updata();
    }
    return () => {
      setiIsStart(false);
    };
  }, [content]);

  useEffect(() => {
    if (editor) {
      setTextLength(editor.getText().replace(/\n/g, "").length);
    }
  }, [editor]);

  return (
    <div className="ssml-tag-editor">
      <div
        className="mask-placeholder"
        onClick={() => {
          setiIsStart(true);
          setShowPlaceholder(true);
          editor?.chain().focus().run();
        }}
        style={{
          display:
            editor?.getText().replace(/\n/g, "").length ||
            content[0]?.content[0]?.type === "text" ||
            showPlaceholder
              ? "none"
              : "block",
        }}>
        {props.placeholder}
      </div>
      {/* <button
        onClick={() => {
          addBreak({
            time: "0.5s",
          });
        }}>
        插入
      </button> */}
      <div
        onClick={() => {
          setiIsStart(true);
          if (setEditPosition && editor) {
            let { from, to } = helper.getSelectionSSML();
            if (content[0]?.type !== "paragraph") {
              from -= 2;
              to -= 2;
            }
            setEditPosition({
              from,
              to,
            });
          }
          if (!window.getSelection()?.toString()) {
            selected({
              value: editor
                .getText()
                .replace(/\n\n\n\n/g, "\n")
                .slice(1),
              range: helper.getSelectionSSML(),
            });
          }
        }}>
        <EditorContent
          editor={editor}
          ref={editorRef}
          style={editorCurrentStyle}
        />
      </div>
      {useCharacterLimit && (
        <span className="textarea-word-limit" style={limitPosition}>
          {textLength}/{limit}
        </span>
      )}
    </div>
  );
});

MyEditor.displayName = "MyEditor";
export default observer(MyEditor);
