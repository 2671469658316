const fontList = [
  { value: "FZFangSong-Z02S", label: "方正仿宋简体" },
  { value: "FZHei-B01S", label: "方正黑体简体" },
  { value: "FZKai-Z03S", label: "方正楷体简体" },
  { value: "FZShuSong-Z01S", label: "方正书宋简体" },
  { value: "SimSun", label: "宋体" },
  { value: "WenQuanYi MicroHei", label: "文泉驿微米黑" },
  { value: "WenQuanYi Zen Hei Mono", label: "文泉驿等宽正黑" },
  { value: "WenQuanYi Zen Hei Sharp", label: "文泉驿点阵正黑" },
  { value: "Microsoft YaHei", label: "微软雅黑" },
  { value: "Yuanti SC", label: "圆体-简常规体" },
  { value: "Alibaba PuHuiTi", label: "阿里巴巴普惠体" },
  { value: "KaiTi", label: "楷体" },
  { value: "SiYuan Heiti", label: "思源黑体" },
  { value: "SiYuan SimSun", label: "思源宋体" },
  { value: "HappyZcool-2016", label: "站酷快乐体" },
];

export default fontList;
