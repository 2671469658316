/* eslint-disable no-useless-escape */
/* eslint-disable no-constant-condition */
import React, { useEffect, useState, useRef } from "react";
import { Image, Button, Input, Message, Tooltip } from "@arco-design/web-react";
import { IconPlusCircle } from "@arco-design/web-react/icon";
import { ReactTyped, Typed } from "react-typed";
import {
  AI_icon,
  assistant_icon,
  plane_icon,
  copy_icon,
  loading_gif,
  stop_icon,
  plane_icon_b,
  start_icon,
} from "@/assets/creationscriptimage";
import "./index.less";
import dayjs from "dayjs";
interface IAssistant {
  width?: number;
  status?: boolean;
  closeIcon?: React.ReactNode;
}
import {
  createSession,
  getScriptChat,
} from "@/services/creativeScript/creationStores";
import LoadingGIF from "@/components/UI/LoadingGIF";
const Assistant: React.FC<IAssistant> = ({
  width = 432,
  status,
  closeIcon,
}) => {
  const [examples, setExamples] = useState<any>([
    {
      content: "帮我生成一张粉底液的图片",
    },
    {
      content: "如何创建剪辑工程?",
    },
    {
      content: "帮我写一个火锅店的推广文案?",
    },
  ]);
  const [typed, setTyped] = React.useState<Typed | undefined>();
  const [searchValue, setSearchValue] = useState("");
  const [chattingRecords, setChattingRecords] = useState<any>([]);
  const [stopGenerate, setStopGenerate] = useState(false);
  const stopGenerateRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(-1);
  const scrollContainerRef = useRef(null);
  const [hoverStatus, setHoverStatus] = useState<boolean>(false);
  const handleOnChange = (e) => {
    setSearchValue(e);
  };
  // 正在进行中
  const isUnderway = () => {
    return (
      ((chattingRecords.length && isComplete === chattingRecords.length - 1) ||
        isLoading) &&
      !stopGenerate
    );
  };
  const getAiAssistantInfo = async (values) => {
    setSearchValue("");
    setStopGenerate(false);
    chattingRecords.push({
      key: `${chattingRecords.length}`,
      content: {
        text: values,
      },
      type: "me",
    });
    setChattingRecords([...chattingRecords]);
    setIsLoading(true);
    scrollContainerRef.current.scrollTop =
      scrollContainerRef.current.scrollHeight;
    const result = await getScriptChat({
      question: values,
      chat_session_id: Number(localStorage.getItem("sessionId")) || "",
    });
    setIsLoading(false);

    if (stopGenerateRef.current) {
      return;
    }
    chattingRecords.push({
      key: `${chattingRecords.length}`,
      content: {
        text: result.data,
      },
      type: "ai",
      timer: Date.now(),
      stop: false,
    });
    setExamples(result.followUp);
    setChattingRecords([...chattingRecords]);
  };
  //  发送
  const handleClickSend = async (values) => {
    getAiAssistantInfo(values);
  };

  // 当消息列表更新后，自动滚动到最底部
  useEffect(() => {
    const scrollableDiv = scrollContainerRef.current;
    let lastScrollTop = 0;
    let smallCunt = 0;
    if (!scrollableDiv) return;
    const observer = new MutationObserver(() => {
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
    });
    observer.observe(scrollableDiv, { childList: true, subtree: true });
    scrollableDiv.addEventListener("scroll", () => {
      if (scrollableDiv.scrollTop < lastScrollTop) {
        if (observer) {
          if (smallCunt) {
            observer.disconnect();
          }
          smallCunt++;
        }
      }
      lastScrollTop = scrollableDiv.scrollTop; // 更新上一次滚动的位置
    });
    return () => observer.disconnect(); // 清除观察器
  }, [isLoading]);

  // 设置为历史记录
  useEffect(() => {
    if (isComplete == -1) {
      setChattingRecords(
        chattingRecords.map((v, i) => ({ ...v, story: true })),
      );
    }
  }, []);
  useEffect(() => {
    stopGenerateRef.current = stopGenerate;
  }, [stopGenerate]);
  const createSessionFun = async () => {
    const res = await createSession();
    if (res?.code == 0) {
      localStorage.setItem("sessionId", res.data.id);
    }
  };
  useEffect(() => {
    const sessionIdStorage = localStorage.getItem("sessionId");
    if (status && !sessionIdStorage) {
      createSessionFun();
    }
  }, [status]);

  const functionconvertMarkdownToImageHtml = (text) => {
    return text.replace(
      /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g,
      (match, altText, url) => {
        return `\n<a href="${url}" target="_blank"> <img src="${url}" alt="${altText}" style="max-width: 100%; height: auto;"> </a> `;
      },
    );
  };
  return (
    <div
      className="assistant-root"
      style={{
        width: width,
      }}>
      <div className="assistant-header">
        <Image
          src={assistant_icon}
          width={85}
          preview={false}
          style={{ marginTop: "-4px" }}
        />
        {closeIcon}
      </div>
      <div className="assistant-box" ref={scrollContainerRef}>
        <div className="assistant-content">
          <div className="assistant-title">
            <Image
              src={AI_icon}
              width={22}
              preview={false}
              className="AI-icon"
            />
            <div>
              <h2 style={{ fontSize: width > 400 ? 16 : 14 }}>
                我是AI小助理，解决你的一切不可能
              </h2>
              <p style={{ fontSize: width > 400 ? 14 : 12 }}>
                您有任何问题，都可以直接向我提问哦～
              </p>
            </div>
          </div>

          {chattingRecords.map((item, index) => {
            return item.type === "me" ? (
              <div key={index}>
                <div className="my-info">
                  <div>{item.content.text}</div>
                </div>
              </div>
            ) : item.stop ? (
              <div className="ai-info" key={index}>
                <div>
                  <p>已停止生成</p>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className="ai-info"
                style={{
                  marginBottom:
                    index !== chattingRecords.length - 1 || isComplete == -1
                      ? 44
                      : 0,
                }}>
                <div>
                  <p>
                    {!item.story ? (
                      <ReactTyped
                        startWhenVisible
                        className={`react-typed-${index}`}
                        typedRef={setTyped}
                        strings={[
                          functionconvertMarkdownToImageHtml(item.content.text),
                        ]}
                        typeSpeed={40}
                        showCursor={false}
                        onStart={() => {
                          setIsComplete(index);
                        }}
                        onComplete={() => {
                          setIsComplete(-1);
                        }}
                      />
                    ) : (
                      <span className={`react-typed-${index}`}>
                        {item.content.text}
                      </span>
                    )}
                  </p>

                  <div
                    className="timer-info"
                    style={{
                      display:
                        index !== chattingRecords.length - 1 || isComplete == -1
                          ? "flex"
                          : "none",
                    }}>
                    <span>
                      {dayjs(item.timer).format("YYYY-MM-DD HH:mm:ss")}
                    </span>
                    <Tooltip content="复制">
                      <Image
                        src={copy_icon}
                        preview={false}
                        className="ai-copy"
                        width={13}
                        onClick={() => {
                          const typedDom: any = document.querySelector(
                            `.react-typed-${index}`,
                          );
                          navigator.clipboard.writeText(typedDom.innerText);
                          Message.success("复制成功");
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className="ai-loading-box"
            style={{
              display: isUnderway() ? "flex" : "none",
            }}>
            <div className="ai-loading">
              {/* <Image src={loading_gif} width={74} preview={false} /> */}
              <LoadingGIF />
            </div>
            <Tooltip content="停止生成" position="right">
              <Image
                onMouseEnter={() => setHoverStatus(true)}
                onMouseLeave={() => setHoverStatus(false)}
                src={hoverStatus ? stop_icon : start_icon}
                width={20}
                preview={false}
                style={{ marginLeft: 14, cursor: "pointer" }}
                onClick={() => {
                  setIsLoading(false);
                  if (isLoading) {
                    chattingRecords.push({
                      key: `${chattingRecords.length}`,
                      content: {
                        text: "已暂停生成",
                      },
                      type: "ai",
                      timer: Date.now(),
                      stop: true,
                    });
                  } else {
                    typed.stop();
                  }
                  setIsLoading(false);
                  setIsComplete(-1);
                  setStopGenerate(true);
                  setChattingRecords([...chattingRecords]);
                }}
              />
            </Tooltip>
          </div>
          {!isLoading && isComplete == -1 && !stopGenerate ? (
            examples.map((item, index) => (
              <div
                className="examples-box"
                key={index}
                onClick={() => {
                  handleClickSend(item.content);
                }}>
                <div className="examples-list">{item.content}</div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="assistant-input-box">
        <Button
          className="new-chat"
          disabled={isUnderway()}
          style={{ display: status ? "block" : "none" }}
          icon={<IconPlusCircle style={{ color: "#999999" }} />}
          onClick={() => {
            setChattingRecords([]);
            setStopGenerate(false);
            Message.success("创建成功");
          }}>
          新聊天
        </Button>
        <Input
          className="chat-input"
          style={{ display: status ? "block" : "none" }}
          value={searchValue}
          onChange={handleOnChange}
          onKeyDown={(e) => {
            if (e.code == "Enter") {
              if (!isUnderway()) {
                if (searchValue.trim()) {
                  handleClickSend(searchValue);
                } else {
                  Message.warning("你没有输入内容哦");
                }
              } else {
                Message.warning("请等待回复完成后再继续发送");
              }
            }
          }}
          suffix={
            <Image
              src={plane_icon_b}
              style={{
                marginRight: 6,
                cursor: isUnderway() ? "not-allowed" : "pointer",
              }}
              width={30}
              preview={false}
              onClick={() => {
                if (!isUnderway()) {
                  if (searchValue.trim()) {
                    handleClickSend(searchValue);
                  } else {
                    Message.warning("你没有输入内容哦");
                  }
                }
              }}
            />
          }
          placeholder="输入..."
        />
      </div>
    </div>
  );
};

export default Assistant;
