import {
  CardTypeEnum,
  toTypeGetTag,
} from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import "./ShowUploadNode.less";

export interface IShowUploadNodeProps {
  id: string;
  cover: string;
  type: CardTypeEnum;
  time?: string;
  name?: string;
}

const ShowUploadNode: React.FC<IShowUploadNodeProps> = (props) => {
  const { cover, type, time, name } = props;
  return (
    <div className="show-up-load-success">
      <div className="cover-img">
        <img src={cover} alt="" />
        <div className="type-tag-wrapper">{toTypeGetTag(type)}</div>
        {time ? <div className="time-wrapper">{time}</div> : <></>}
      </div>
      {name ? <p>{name}</p> : <></>}
    </div>
  );
};

export default ShowUploadNode;
