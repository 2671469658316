import React from "react";
import "./index.less";
const LoadingGIF = () => {
  return (
    <div className="load_11">
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </div>
  );
};

export default LoadingGIF;
